export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "eu-central-1",
    API_URL: "https://g5i552umng.execute-api.eu-central-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_GsmAWL1p6",
    APP_CLIENT_ID: "48v8inv08dd7f9u3u8ml5ff8u1",
    IDENTITY_POOL_ID: "eu-central-1:dbbaee23-8182-4468-8e44-86da061a26ea"
  }
};